import { useDeleteSite, usePartner, usePatchSite, useSite } from '@app/api/partners.api';
import serverError from '@app/assets/images/server-error.svg';
import { Error } from '@app/components/Error/Error';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Card } from '@app/components/common/Card/Card';
import { Loading } from '@app/components/common/Loading';
import { WarningModal } from '@app/components/common/Modal/Modal';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { getOriginalFilename } from '@app/components/tables/BasicTable/DataSourcesTables';
import { notificationController } from '@app/controllers/notificationController';
import { PartnerModel } from '@app/domain/PartnerModel';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

interface FieldData {
  name: any;
  value?: any;
}

export const BreadcrumbPartner = (partner: PartnerModel) => (
  <Breadcrumb>
    <BreadcrumbItem>
      <Link to="/chats">Chats</Link>
    </BreadcrumbItem>
    <BreadcrumbItem>
      <Link to={`/chat/${partner.id}`}>{partner.name}</Link>
    </BreadcrumbItem>
  </Breadcrumb>
);

const DataSourcePage: React.FC = () => {
  const { partnerID, siteID } = useParams();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const { isLoading: isLoadingPartner, error: errorLoadingPartner, data: partner } = usePartner(partnerID!);
  const { isLoading: isLoadingSite, error: errorLoadingSite, data: site } = useSite(partnerID!, siteID!);
  const patchSite = usePatchSite(partnerID!, siteID!);
  const deleteSite = useDeleteSite(partnerID!, siteID!);
  const navigate = useNavigate();
  const [fields, setFields] = useState<FieldData[]>([
    { name: 'base_url', value: '' },
    { name: 'max_scraped_links', value: 0 },
    { name: 'max_chunk_size', value: 0 },
    { name: 'min_chunk_size', value: 0 },
  ]);
  useEffect(() => {
    setFields([
      { name: 'base_url', value: getOriginalFilename(site?.base_url ?? '') },
      { name: 'max_scraped_links', value: site?.max_scraped_links ?? 0 },
      { name: 'max_chunk_size', value: site?.max_chunk_size ?? 0 },
      { name: 'min_chunk_size', value: site?.min_chunk_size ?? 0 },
    ]);
  }, [site]);

  if (isLoadingPartner || isLoadingSite) {
    return <Loading />;
  }

  if (!!errorLoadingPartner || !!errorLoadingSite) {
    return <Error img={serverError} msg="Internal server error" />;
  }

  const onFinish = async (values = {}) => {
    const payload = {
      ...values,
    };
    try {
      await patchSite.mutateAsync(payload);
    } catch (error) {
      console.error(error);
      notificationController.error({ message: 'Something went wrong. Please retry in a few seconds!' });
    } finally {
      notificationController.success({ message: 'Your changes have been saved!' });
      setFieldsChanged(false);
    }
  };

  const onDeleteSite = async () => {
    try {
      await deleteSite.mutateAsync();
    } catch (error) {
      console.error(error);
      notificationController.error({ message: 'Something went wrong. Please retry in a few seconds!' });
    } finally {
      notificationController.success({ message: 'Data source is deleted successfully!' });
      navigate(`/chat/${partnerID}`);
    }
  };

  function displayWarningModal(): void {
    WarningModal({
      title: 'Danger',
      content: (
        <>
          <p>
            All data scraped for the following data source will be completely removed. This action is undone. Are you
            sure?
          </p>
          <i>{getOriginalFilename(site?.base_url ?? '')}</i>
        </>
      ),
      onOk: () => {
        onDeleteSite();
      },
      okCancel: true,
      okType: 'danger',
      okText: 'Remove',
    });
  }

  return (
    <>
      {BreadcrumbPartner(partner!)}
      <PageTitle>Data Source</PageTitle>
      <Row gutter={[30, 30]}>
        <Col xs={24} sm={24} xl={24}>
          <Card
            id="data source form"
            title={'Data Source'}
            padding="1.25rem 1.25rem 0"
            style={{ marginTop: '1.25rem' }}
          >
            <BaseButtonsForm
              isFieldsChanged={isFieldsChanged}
              onFieldsChange={() => setFieldsChanged(true)}
              layout="vertical"
              fields={fields}
              name="edit_data_source"
              onFinish={onFinish}
              footer={
                <Button type="primary" htmlType="submit" loading={patchSite.isLoading}>
                  Submit
                </Button>
              }
            >
              <BaseForm.Item name="base_url" label="Data Source Url">
                <Input disabled />
              </BaseForm.Item>
              <BaseForm.Item name="max_scraped_links" label="Maximum Scraped Links">
                <InputNumber />
              </BaseForm.Item>
              <BaseForm.Item name="max_chunk_size" label="Maximum Chunk Size">
                <InputNumber />
              </BaseForm.Item>
              <BaseForm.Item name="min_chunk_size" label="Minimum Chunk Size">
                <InputNumber />
              </BaseForm.Item>
            </BaseButtonsForm>
          </Card>
        </Col>
      </Row>
      <Row gutter={[30, 30]} style={{ marginTop: '40px' }}>
        <Col xs={24} sm={24} xl={24}>
          <Card id="danger zone" title="Danger Zone" padding="1.25rem 1.25rem 2.5rem">
            <Button type="primary" danger htmlType="submit" loading={patchSite.isLoading} onClick={displayWarningModal}>
              Delete Data Source
            </Button>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DataSourcePage;
