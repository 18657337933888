import { getRealmFromConfig } from '@app/App';
import { postUser } from '@app/api/partners.api';
import { readConfigurationName } from '@app/services/localStorage.service';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import mixpanel from 'mixpanel-browser';
import React, { useCallback, useEffect } from 'react';
import { Loading } from '../common/Loading';

const LoginCallback: React.FunctionComponent = () => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken(readConfigurationName());
  const realm = getRealmFromConfig(readConfigurationName());
  console.log('Authentication complete, redirecting');

  const fetch = useCallback(() => {
    // TODO: move this to another place
    const headers = {
      'ChatBD-Realm': realm,
      'ChatBD-Client-ID': 'chatbd-dashboard',
      Authorization: `Bearer ${accessToken}`,
    };
    if (!!accessToken) {
      postUser(headers)
        .then(() => {
          console.log('Sync user with server', accessTokenPayload['email']);
          if (process.env.REACT_APP_ENV === 'prod') {
            mixpanel.identify(accessTokenPayload['email']);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [accessToken, accessTokenPayload, realm]);

  useEffect(() => {
    fetch();
  }, [fetch]);
  return <Loading />;
};

export default LoginCallback;
