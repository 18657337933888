import { LoginForm } from '@app/components/auth/LoginForm/LoginForm';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { HandleConfigurationChangeProps } from '@app/components/router/AppRouter';
import { useOidc } from '@axa-fr/react-oidc';
import React from 'react';
import { Navigate } from 'react-router-dom';

const LoginPage: React.FC<HandleConfigurationChangeProps> = ({ handleConfigurationChange, configurationName }) => {
  const { isAuthenticated } = useOidc(configurationName);

  if (!isAuthenticated) {
    return (
      <>
        <PageTitle>Login</PageTitle>
        <LoginForm handleConfigurationChange={handleConfigurationChange} configurationName={configurationName} />
      </>
    );
  }
  return <Navigate to="/" replace />;
};

export default LoginPage;
