import { CONFIG_WORKSPACE_NAME_MAP, WORKSPACE_CONFIG_MAP } from '@app/App';
import { ReactComponent as FPTIcon } from '@app/assets/icons/fpt-logo.svg';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { HandleConfigurationChangeProps } from '@app/components/router/AppRouter';
import { useOidc } from '@axa-fr/react-oidc';
import { Divider, Row } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface LoginFormData {
  workspace: string;
}

export const initValues: LoginFormData = {
  workspace: '',
};

export const LoginForm: React.FC<HandleConfigurationChangeProps> = ({
  handleConfigurationChange,
  configurationName,
}) => {
  const [form] = BaseForm.useForm();
  const { login } = useOidc(configurationName);
  const workspaceName = CONFIG_WORKSPACE_NAME_MAP[configurationName];
  const { workspace } = useParams();
  const navigate = useNavigate();
  const navigateUnauthorized = useCallback(() => navigate('/auth/login'), [navigate]);

  const applyConfigIfAvailable = useCallback(
    (configKey: string) => {
      const config = WORKSPACE_CONFIG_MAP[configKey.toLowerCase()];

      return config ? handleConfigurationChange(config) : navigateUnauthorized();
    },
    [handleConfigurationChange, navigateUnauthorized],
  );

  const handleSubmit = ({ workspace }: LoginFormData) => {
    console.log('Login form', configurationName);

    setTimeout(() => {
      const config = WORKSPACE_CONFIG_MAP[workspace.toLowerCase()];

      return config
        ? handleConfigurationChange(config)
        : form.setFields([
            {
              name: 'workspace',
              errors: ["We can't find your workspace"],
            },
          ]);
    }, 1000);
  };

  useEffect(() => {
    return workspace ? applyConfigIfAvailable(workspace) : navigateUnauthorized();
  }, [navigateUnauthorized, applyConfigIfAvailable, workspace]);

  return (
    <Auth.FormWrapper>
      <BaseForm
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark="optional"
        initialValues={initValues}
        form={form}
      >
        <Auth.FormTitle>Workspace</Auth.FormTitle>
        <Row justify={'center'} style={{ marginBottom: '15px' }}>
          {workspaceName === 'FPT' ? (
            <FPTIcon width={256} height={128} />
          ) : (
            <img
              src={'https://www.chatbd.ai/assets/logo-with-text-2f3766b9.svg'}
              alt="ChatBD"
              width={256}
              height={128}
            />
          )}
        </Row>
        <Auth.SubmitButton type="primary" onClick={() => login()}>
          {`Log in to ${workspaceName}`}
        </Auth.SubmitButton>
        {!workspace && (
          <>
            <Divider plain>Or switch to another Workspace</Divider>
            <Auth.FormItem name="workspace">
              <Auth.FormInput placeholder={'Search for a Workspace'} />
            </Auth.FormItem>
          </>
        )}
      </BaseForm>
    </Auth.FormWrapper>
  );
};
