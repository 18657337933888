import { triggerScrape } from '@app/api/partners.api';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm';
import { SiteBaseModel } from '@app/domain/SiteModel';
import { FONT_SIZE, media } from '@app/styles/themes/constants';
import { getFilenameFromURL } from '@app/utils/utils';
import { Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Pagination } from 'api/table.api';
import { Table } from 'components/common/Table/Table';
import { Button } from 'components/common/buttons/Button/Button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

const AsyncButton = styled(Button)`
  @media only screen and ${media.xs} {
    font-size: ${FONT_SIZE.xs};
  }
  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.md};
  }
`;
const initialPagination: Pagination = {
  current: 1,
  pageSize: 100,
};

interface Props {
  sites: SiteBaseModel[];
  isLoading: boolean;
}

export const getOriginalFilename = (url: string) => {
  if (!url) {
    return url;
  }

  if (new URL(url).hostname.includes('s3.amazonaws.com')) {
    const filenameWithUUID = getFilenameFromURL(url);
    return filenameWithUUID.split('_').slice(1).join('_');
  }
  return url;
};

export const DataSourcesTable: React.FC<Props> = ({ sites, isLoading = false }) => {
  const { partnerID } = useParams();
  const navigate = useNavigate();

  const [tableData, setTableData] = useState<{ data: SiteBaseModel[]; pagination: Pagination; loading: boolean }>({
    data: sites,
    pagination: initialPagination,
    loading: isLoading,
  });

  const [open, setOpen] = useState<{ [key: string]: boolean }>({});
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleScrape = async (site: SiteBaseModel, forceRefresh = false) => {
    setConfirmLoading(true);
    const payload = {
      force_refresh: forceRefresh,
      site_id: site.id,
    };
    try {
      await triggerScrape(payload);
    } catch (error) {
      console.error(error);
    }
    setConfirmLoading(false);
  };

  useEffect(() => {
    setTableData({
      data: sites,
      pagination: initialPagination,
      loading: false,
    });
  }, [sites]);

  const columns: ColumnsType<SiteBaseModel> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Data Source',
      dataIndex: 'base_url',
      render: (base_url: string) => <span>{getOriginalFilename(base_url)}</span>,
    },
    {
      title: t('tables.actions'),
      dataIndex: 'id',
      render: (id, record) => {
        const siteID = record.id.toString();
        const scrapeDataPopUp = `${siteID}-scrape-data`;
        const scrapeDataForceRefreshPopUp = `${siteID}-scrape-data-force-refresh`;
        return (
          <Space>
            <Button type="default" onClick={() => navigate(`/chat/${partnerID}/source/${siteID}`)}>
              Edit
            </Button>
            <Popconfirm
              title={'Scrape this Data Source?'}
              open={open[scrapeDataPopUp]}
              onConfirm={async () => await handleScrape(record, false)}
              okButtonProps={{ loading: confirmLoading }}
              onCancel={() => setOpen({ ...open, scrapeDataPopUp: false })}
              okText="Confirm"
            >
              <AsyncButton type="ghost" onClick={() => setOpen({ ...open, scrapeDataPopUp: true })}>
                Scrape Data
              </AsyncButton>
            </Popconfirm>
            <Popconfirm
              title={'Force Re-scrape this Data Source?'}
              open={open[scrapeDataForceRefreshPopUp]}
              onConfirm={async () => await handleScrape(record, true)}
              okButtonProps={{ loading: confirmLoading }}
              onCancel={() => setOpen({ ...open, scrapeDataForceRefreshPopUp: false })}
              okText="Confirm"
            >
              <AsyncButton type="ghost" danger onClick={() => setOpen({ ...open, scrapeDataForceRefreshPopUp: true })}>
                Force Re-Scrape Data
              </AsyncButton>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={tableData.data.map((data, index) => ({ ...data, key: index }))}
      pagination={tableData.pagination}
      loading={tableData.loading}
      scroll={{ x: 800 }}
      bordered
    />
  );
};
