import { getRealmFromConfig } from '@app/App';
import { postPartners, uploadFile } from '@app/api/partners.api';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import { readConfigurationName } from '@app/services/localStorage.service';
import { mergeBy } from '@app/utils/utils';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import mixpanel from 'mixpanel-browser';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './StepForm.styles';
import { Steps } from './StepForm.styles';
import { ConfirmStep } from './Steps/ConfirmStep';
import { DataSources } from './Steps/DataSourcesStep';
import { InfoStep } from './Steps/InfoStep';
import { ProcessStep, SiteList } from './Steps/ProcessStep';
interface FormValues {
  [key: string]: string | undefined;
}

interface FieldData {
  name: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
}

export const PartnerCreateForm: React.FC = () => {
  const [current, setCurrent] = useState(0);
  const [form] = BaseForm.useForm();
  const [fields, setFields] = useState<FieldData[]>([
    { name: 'name', value: '' },
    { name: 'baseUrl', value: '' },
    { name: 'prompt', value: null },
    { name: 'logoUrl', value: '' },
    { name: 'logoFile', value: null },
    { name: 'chat_avatar_file', value: null },
    { name: 'chat_avatar', value: '' },
    { name: 'primaryColor', value: '#09A4C4' },
    { name: 'documents', value: [] },
    { name: 'sites', value: undefined },
    { name: 'languageCode', value: 'en' },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [siteList, setSiteList] = useState<SiteList[]>([]);
  const [partnerID, setParnerID] = useState('');
  const { accessToken } = useOidcAccessToken(readConfigurationName());
  const realm = getRealmFromConfig(readConfigurationName());

  const formLabels: FormValues = {
    name: t('forms.partnerCreateLables.name'),
    baseUrl: t('forms.partnerCreateLables.baseUrl'),
    prompt: t('forms.partnerCreateLables.prompt'),
    logoUrl: t('forms.partnerCreateLables.logoUrl'),
    chat_avatar: t('forms.partnerCreateLables.chat_avatar'),
    primaryColor: 'Chat Theme Color',
    documents: 'Documents',
    sites: 'Sites',
    languageCode: 'Default Language',
    logoFile: 'Logo File',
    chat_avatar_file: 'Avatar File',
  };

  const formValues = fields.map((item) => ({
    name: formLabels[item.name],
    value: item.value,
  }));

  const next = () => {
    form.validateFields().then(() => {
      setCurrent(current + 1);
    });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onFinish = async () => {
    if (process.env.REACT_APP_ENV === 'prod') {
      mixpanel.track('Create Chat');
    }

    setIsLoading(true);
    const documents = form.getFieldValue('documents');
    const baseUrls: string[] = (form.getFieldValue('sites') || [])
      .filter((f: any) => !!f)
      .map((f: any) => f.baseUrl.replace(/\/$/, ''));
    let logoUrl = form.getFieldValue('logoUrl');
    const logoFile = form.getFieldValue('logoFile');
    let avatarUrl = form.getFieldValue('chat_avatar');
    const avatarFile = form.getFieldValue('chat_avatar_file');
    if (!logoUrl && !!logoFile) {
      const { url } = await uploadFile(logoFile.originFileObj);
      logoUrl = url;
    }
    if (!avatarUrl && !!avatarFile) {
      const { url } = await uploadFile(avatarFile.originFileObj);
      avatarUrl = url;
    }
    for (const document of documents) {
      const { url } = await uploadFile(document.originFileObj);
      baseUrls.push(url);
    }
    const sites = baseUrls.map((url) => ({ base_url: url }));
    const payload = {
      name: form.getFieldValue('name'),
      prompt: form.getFieldValue('prompt'),
      logo_url: logoUrl,
      keygen_license_key: 'AD419B-766082-6C127B-CB6066-A39EE8-V3',
      keygen_license_id: 'fb9ca570-67cd-4b7b-9710-d2f5fb1c9cf0',
      styles: {
        primary_color: form.getFieldValue('primaryColor'),
        chat_avatar: avatarUrl,
      },
      sites,
      language_code: form.getFieldValue('languageCode'),
    };

    const headers = {
      'ChatBD-Realm': realm,
      'ChatBD-Client-ID': 'chatbd-dashboard',
      Authorization: `Bearer ${accessToken}`,
    };

    postPartners(payload, headers)
      .then((data) => {
        setIsLoading(false);
        setSiteList([...data['sites']]);
        setParnerID(data['id']);
        setCurrent(3);
        return data;
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
        notificationController.error({ message: 'Something went wrong. Please retry in a few seconds!' });
      });
  };

  const steps = [
    {
      title: t('forms.stepFormLabels.info'),
    },
    {
      title: 'Data Sources',
    },
    {
      title: t('forms.stepFormLabels.confirm'),
    },
    {
      title: 'Process Data',
    },
  ];

  const formFieldsUi = [
    <InfoStep key="1" form={form} />,
    <DataSources key="2" form={form} />,
    <ConfirmStep key="3" formValues={formValues} />,
    <ProcessStep key="4" siteList={siteList} partnerID={partnerID} />,
  ];

  return (
    <BaseForm
      name="stepForm"
      form={form}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        console.log('fields', fields, allFields);

        const currentFields = allFields
          .map((item) => ({
            name: Array.isArray(item.name) ? item.name[0] : '',
            value: item.value,
          }))
          .filter((item) => !(item.name === 'sites' && !Array.isArray(item.value)));
        console.log('currentFields', currentFields);

        const uniqueData = mergeBy(fields, currentFields, 'name');
        setFields(uniqueData);
      }}
    >
      <Steps labelPlacement="vertical" size="small" current={current} items={steps} />

      <div>{formFieldsUi[current]}</div>
      <S.Row>
        {current > 0 && current < steps.length - 1 && (
          <S.PrevButton type="default" onClick={() => prev()} disabled={isLoading}>
            {t('forms.stepFormLabels.previous')}
          </S.PrevButton>
        )}
        {current < steps.length - 2 && (
          <Button type="primary" onClick={() => next()} disabled={isLoading}>
            {t('forms.stepFormLabels.next')}
          </Button>
        )}
        {current === steps.length - 2 && (
          <Button type="primary" onClick={async () => await onFinish()} loading={isLoading}>
            {t('forms.stepFormLabels.done')}
          </Button>
        )}
      </S.Row>
    </BaseForm>
  );
};
