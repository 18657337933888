import { usePartner } from '@app/api/partners.api';
import serverError from '@app/assets/images/server-error.svg';
import { Error } from '@app/components/Error/Error';
import { Card } from '@app/components/common/Card/Card';
import { Loading } from '@app/components/common/Loading';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { DataSourceAddForm } from '@app/components/forms/StepForm/DataSourceAddForm';
import { TablesWrapper } from '@app/components/tables/Tables/Tables.styles';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BreadcrumbPartner } from './DataSourcePage';

const DataSourceAddPage: React.FC = () => {
  const { partnerID } = useParams();
  const { isLoading, error, data: partner } = usePartner(partnerID!);

  if (isLoading) {
    return <Loading />;
  }

  if (!!error) {
    return <Error img={serverError} msg="Internal server error" />;
  }

  return (
    <>
      {BreadcrumbPartner(partner!)}
      <PageTitle>Add Data Source</PageTitle>
      <TablesWrapper>
        <Card id="basic-table" title="Add Data Source" padding="1.25rem 1.25rem 1.875rem">
          <DataSourceAddForm partner={partner!} />
        </Card>
      </TablesWrapper>
    </>
  );
};

export default DataSourceAddPage;
