import { Col, UploadFile } from 'antd';
import * as S from '../StepForm.styles';
import { languageMapping } from './InfoStep';

interface Field {
  name?: string;
  value: string | UploadFile[] | string[] | UploadFile;
}

interface Step4Props {
  formValues: Field[];
}

export const ConfirmStep: React.FC<Step4Props> = ({ formValues }) => {
  console.log('Confirm Step: form values', formValues);

  return (
    <S.Details>
      {formValues
        .filter((item) => {
          if (!!item.value) {
            if (Array.isArray(item.value)) {
              return !!item.value.length; // filter out empty array items
            }
            return !!item.value;
          }
        })
        .map((item: Field, index: number) => {
          if (item.name === 'Logo Url' || item.name === 'Avatar Url') {
            return (
              <S.DetailsRow key={index}>
                <Col>
                  <S.DetailsTitle>{item.name.split(' ')[0]}</S.DetailsTitle>
                </Col>
                <Col>
                  <S.ImageHolder>
                    <S.Image src={item.value as string} alt={item.name} />
                  </S.ImageHolder>
                </Col>
              </S.DetailsRow>
            );
          }

          if (item.name === 'Logo File' || item.name === 'Avatar File') {
            const file = item.value as UploadFile;
            const src = URL.createObjectURL(file.originFileObj as File);
            return (
              <S.DetailsRow key={index}>
                <Col>
                  <S.DetailsTitle>{item.name.split(' ')[0]}</S.DetailsTitle>
                </Col>
                <Col>
                  <S.ImageHolder>
                    <S.Image src={src} alt={item.name} />
                  </S.ImageHolder>
                </Col>
              </S.DetailsRow>
            );
          }

          if (item.name === 'Chat Theme Color') {
            return (
              <S.DetailsRow key={index}>
                <S.DetailsTitle>{item.name}</S.DetailsTitle>
                <div
                  style={{
                    width: 200,
                    height: 50,
                    marginTop: 20,
                    backgroundColor: item.value as string,
                    borderRadius: 8,
                  }}
                />
              </S.DetailsRow>
            );
          }
          if (item.name === 'Default Language') {
            return (
              <S.DetailsRow key={index}>
                <S.DetailsTitle>{item.name}</S.DetailsTitle>
                <S.DetailsValue>{languageMapping(item.value as string)}</S.DetailsValue>
              </S.DetailsRow>
            );
          }

          if (item.name === 'Documents') {
            return (
              <S.DetailsRow key={index}>
                <Col>
                  <S.DetailsTitle>{item.name}</S.DetailsTitle>
                </Col>
                <Col>
                  {(item.value as UploadFile[]).map((f) => {
                    return <S.DetailsValue key={f.uid}>{decodeURIComponent(f.name)}</S.DetailsValue>;
                  })}
                </Col>
              </S.DetailsRow>
            );
          }

          if (item.name === 'Sites') {
            return (
              <S.DetailsRow key={index}>
                <Col>
                  <S.DetailsTitle>{item.name}</S.DetailsTitle>
                </Col>
                <Col>
                  {(item.value as any)
                    .filter((f: any) => !!f)
                    .map((f: { baseUrl: string }) => (
                      <S.DetailsValue key={f.baseUrl}>{f.baseUrl}</S.DetailsValue>
                    ))}
                </Col>
              </S.DetailsRow>
            );
          }
          return (
            <S.DetailsRow key={index}>
              <S.DetailsTitle>{item.name}</S.DetailsTitle>
              <S.DetailsValue>{item.value}</S.DetailsValue>
            </S.DetailsRow>
          );
        })}
    </S.Details>
  );
};
