import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { PartnerCreateForm } from '@app/components/forms/StepForm/PartnerCreateForm';
import { Card, TablesWrapper } from '@app/components/tables/Tables/Tables.styles';
import React from 'react';

const CreateChatPage: React.FC = () => {
  const desktopLayout = (
    <>
      <TablesWrapper>
        <Card id="step-form" title={'Create a ChatBD Bot'} padding="1.25rem">
          <PartnerCreateForm />
        </Card>
      </TablesWrapper>
    </>
  );

  return (
    <>
      <PageTitle>Onboarding</PageTitle>
      {desktopLayout}
    </>
  );
};

export default CreateChatPage;
