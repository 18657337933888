import { FormOutlined } from '@ant-design/icons';
import { ReactComponent as NftIcon } from '@app/assets/icons/nft-icon.svg';
import React from 'react';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Onboarding',
    key: 'create partner',
    url: '/onboarding',
    icon: <FormOutlined />,
  },
  {
    title: 'Chats',
    key: 'chats',
    url: '/chats',
    icon: <NftIcon />,
  },
];
