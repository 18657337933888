import { getRealmFromConfig } from '@app/App';
import { readConfigurationName } from '@app/services/localStorage.service';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useEffect, useState } from 'react';

export const useAuthHeaders = () => {
  const realm = getRealmFromConfig(readConfigurationName());
  const { accessToken } = useOidcAccessToken(readConfigurationName());
  const [headers, setHeaders] = useState<any>(null);

  useEffect(() => {
    setHeaders({
      'ChatBD-Realm': realm,
      'ChatBD-Client-ID': 'chatbd-dashboard',
      Authorization: `Bearer ${accessToken}`,
    });
  }, [accessToken, realm]);

  return headers;
};
