import { readConfigurationName } from '@app/services/localStorage.service';
import { WithChildrenProps } from '@app/types/generalTypes';
import { useOidc } from '@axa-fr/react-oidc';
import React from 'react';
import { Navigate } from 'react-router-dom';

const RequireAuthOIDC: React.FC<WithChildrenProps> = ({ children }) => {
  const { isAuthenticated } = useOidc(readConfigurationName());

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" replace />;
  }

  return <>{children}</>;
};

export default RequireAuthOIDC;
