import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface LoadingProps {
  size?: string;
  color?: string;
}

export const Loading: React.FC<LoadingProps> = () => {
  return (
    <SpinnerContainer>
      <div style={{ marginTop: '10px' }}>
        <Spin />
      </div>
    </SpinnerContainer>
  );
};

const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
