import { getRealmFromConfig } from '@app/App';
import { getPartners } from '@app/api/partners.api';
import { Status } from '@app/components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/Status/Status';
import { Priority } from '@app/constants/enums/priorities';
import { PartnerModel } from '@app/domain/PartnerModel';
import { SiteBaseModel } from '@app/domain/SiteModel';
import { useMounted } from '@app/hooks/useMounted';
import { readConfigurationName } from '@app/services/localStorage.service';
import { defineColorByPriority } from '@app/utils/utils';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { Space, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Pagination } from 'api/table.api';
import { Table } from 'components/common/Table/Table';
import { Button } from 'components/common/buttons/Button/Button';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

export const PartnersTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: PartnerModel[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const { accessToken } = useOidcAccessToken(readConfigurationName());
  const realm = getRealmFromConfig(readConfigurationName());
  const navigate = useNavigate();

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      const headers = {
        'ChatBD-Realm': realm,
        'ChatBD-Client-ID': 'chatbd-dashboard',
        Authorization: `Bearer ${accessToken}`,
      };
      getPartners(pagination, headers).then((res) => {
        // Sort data to prevent order changes every time because of query fetching
        res.data.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });

        if (isMounted.current) {
          setTableData({
            data: [...res.data],
            pagination: {
              current: res.current,
              pageSize: res.page_size,
              total: res.total,
            },
            loading: false,
          });
          const newOpen: { [key: string]: boolean } = {};
          res.data.forEach((p) => {
            newOpen[p.id.toString()] = false;
          });
        }
      });
    },
    [accessToken, isMounted, realm],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    fetch(pagination);
  };

  const handleDeleteRow = (rowId: number) => {
    setTableData({
      ...tableData,
      data: tableData.data.filter((item) => item.id !== rowId),
      pagination: {
        ...tableData.pagination,
        total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
      },
    });
  };

  const columns: ColumnsType<PartnerModel> = [
    {
      title: t('common.name'),
      dataIndex: 'name',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'No. Data Sources',
      width: '10%',
      dataIndex: 'sites',
      render: (sites: SiteBaseModel[]) => <span>{sites.length}</span>,
    },
    {
      title: 'Search Mode',
      width: '10%',
      key: 'tags',
      dataIndex: 'search_type',
      render: (search_type: string) => (
        <Status color={defineColorByPriority(Priority.INFO)} text={search_type.toLocaleUpperCase()} />
      ),
    },
    {
      title: t('tables.actions'),
      dataIndex: 'id',
      width: '15%',
      render: (id, record) => {
        const partnerID = record.id.toString();
        return (
          <Space>
            <Button
              type="link"
              onClick={() => {
                window.open(`${process.env.REACT_APP_CHAT_URL}/${id}`, '_blank');
              }}
            >
              Go to App
            </Button>
            <Button type="default" onClick={() => navigate(`/chat/${partnerID}`)}>
              Edit
            </Button>
            <Button disabled type="default" danger onClick={() => handleDeleteRow(id)}>
              {t('tables.delete')}
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={tableData.data.map((data, index) => ({ ...data, key: index }))}
      pagination={tableData.pagination}
      loading={tableData.loading}
      onChange={handleTableChange}
      scroll={{ x: 800 }}
      bordered
    />
  );
};
