import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { PartnersTable } from '@app/components/tables/BasicTable/PartnersTable';
import { Card, TablesWrapper } from '@app/components/tables/Tables/Tables.styles';
import React from 'react';

const PartnersPage: React.FC = () => {
  const desktopLayout = (
    <>
      <TablesWrapper>
        <Card id="basic-table" title="Your Chats" padding="1.25rem 1.25rem 0">
          <PartnersTable />
        </Card>
      </TablesWrapper>
    </>
  );

  return (
    <>
      <PageTitle>Your Chats</PageTitle>
      {desktopLayout}
    </>
  );
};

export default PartnersPage;
