import { patchPartner, triggerScrape } from '@app/api/partners.api';
import { Progress } from '@app/components/common/Progress/Progress';
import { Button } from '@app/components/common/buttons/Button/Button';
import { notificationController } from '@app/controllers/notificationController';
import { Row } from 'antd';
import { useState } from 'react';
import { useInterval } from 'usehooks-ts';
import * as S from '../StepForm.styles';

export interface SiteList {
  base_url: string;
  id: string;
}
interface Step4Props {
  siteList: SiteList[];
  partnerID: string;
}

export const ProcessStep: React.FC<Step4Props> = ({ siteList, partnerID }) => {
  const [isScraping, setIsScraping] = useState(false);
  const [isProcessed, setIsProcessed] = useState(false);
  const [seconds, setSeconds] = useState<number>(0);
  const [prompt, setPrompt] = useState('');

  useInterval(
    () => {
      setSeconds(seconds + 1);
      if (seconds > 60) {
        setIsScraping(false); // stop
        setIsProcessed(true);
      }
    },
    // Delay in milliseconds or null to stop it
    isScraping ? 1000 : null,
  );

  const onModifyPrompt = async () => {
    if (!prompt) return;
    try {
      await patchPartner(partnerID, { prompt });
      notificationController.success({ message: 'Your changes has been saved' });
    } catch (error) {
      console.error(error);
      notificationController.error({ message: 'Something went wrong. Please retry in a few seconds.' });
    }
  };

  const onProcessData = async () => {
    setIsScraping(true); // start timer

    for (const site of siteList) {
      const payload = {
        force_refresh: false,
        site_id: site.id,
      };

      try {
        await triggerScrape(payload);
      } catch (error) {
        setIsScraping(false);
        console.log(error);
        notificationController.error({ message: 'Something went wrong. Please retry in a few seconds!' });
      }
    }
  };

  return (
    <S.Details>
      {!isScraping && !isProcessed && (
        <>
          <Row>The magic is about to begin. Do you want to start scraping the new data sources?</Row>
          <Button type="primary" onClick={onProcessData} loading={isScraping}>
            Start scraping
          </Button>
        </>
      )}
      {(isScraping || isProcessed) && (
        <>
          {seconds > 0 && (
            <>
              <Row>You can start chatting in about 1 minutes</Row>
              <Progress percent={Math.round(seconds * 1.67)} />
              {seconds > 0 && seconds < 10 && <div>{`Initialing...`}</div>}
              {seconds >= 10 && seconds < 60 && <div>{`Scraping...`}</div>}
              {/*               <div>
                <Row>In the meantime, you can customize the default Prompt for your chat as you wish</Row>
                <BaseForm.Item name="prompt" label={'Prompt'}>
                  <TextArea rows={4} value={prompt} onChange={(e) => setPrompt(e.target.value)} />
                </BaseForm.Item>
                <Button type="primary" disabled={!prompt} onClick={onModifyPrompt}>
                  Modify Prompt
                </Button>
              </div> */}
              <Button
                type="link"
                onClick={() => window.open(`${process.env.REACT_APP_CHAT_URL}/${partnerID}`, '_blank')}
                loading={seconds < 60}
              >
                Go to ChatBD app
              </Button>
            </>
          )}
        </>
      )}
    </S.Details>
  );
};
