import { uploadFile, useAddSite } from '@app/api/partners.api';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import { PartnerModel } from '@app/domain/PartnerModel';
import { mergeBy } from '@app/utils/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './StepForm.styles';
import { Steps } from './StepForm.styles';
import { ConfirmStep } from './Steps/ConfirmStep';
import { DataSources } from './Steps/DataSourcesStep';
import { ProcessStep, SiteList } from './Steps/ProcessStep';

interface FieldData {
  name: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
}

interface FormValues {
  [key: string]: string | undefined;
}

interface Props {
  partner: PartnerModel;
}

export const DataSourceAddForm: React.FC<Props> = ({ partner }) => {
  const [current, setCurrent] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = BaseForm.useForm();
  const [fields, setFields] = useState<FieldData[]>([
    { name: 'documents', value: [] },
    { name: 'sites', value: undefined },
  ]);
  const { t } = useTranslation();
  const [siteList, setSiteList] = useState<SiteList[]>([]);
  const addSite = useAddSite(partner.id);

  const next = () => {
    form.validateFields().then(() => {
      setCurrent(current + 1);
    });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onFinish = async () => {
    setIsLoading(true);
    const documents = form.getFieldValue('documents');
    const baseUrls: string[] = (form.getFieldValue('sites') || [])
      .filter((f: any) => !!f)
      .map((f: any) => f.baseUrl.replace(/\/$/, ''));
    for (const document of documents) {
      const { url } = await uploadFile(document.originFileObj);
      baseUrls.push(url);
    }
    const sites = baseUrls.map((url) => ({ base_url: url }));
    const payload = {
      sites,
    };

    try {
      const data = await addSite.mutateAsync(payload);
      setSiteList([...data['sites']]);
      setCurrent(current + 1);
    } catch (error) {
      notificationController.error({ message: 'Something went wrong. Please retry in a few seconds!' });
    } finally {
      setIsLoading(false);
    }
  };

  const steps = [
    {
      title: 'Data Sources',
    },
    {
      title: t('forms.stepFormLabels.confirm'),
    },
    {
      title: 'Process Data',
    },
  ];

  const formLabels: FormValues = {
    documents: 'Documents',
    sites: 'Sites',
  };

  const formValues = fields.map((item) => ({
    name: formLabels[item.name],
    value: item.value,
  }));

  const formFieldsUi = [
    <DataSources key="1" form={form} partner={partner} />,
    <ConfirmStep key="2" formValues={formValues} />,
    <ProcessStep key="3" siteList={siteList} partnerID={partner.id} />,
  ];

  return (
    <BaseForm
      name="stepForm"
      form={form}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        console.log('fields', fields, allFields);

        const currentFields = allFields
          .map((item) => ({
            name: Array.isArray(item.name) ? item.name[0] : '',
            value: item.value,
          }))
          .filter((item) => !(item.name === 'sites' && !Array.isArray(item.value)));
        console.log('currentFields', currentFields);

        const uniqueData = mergeBy(fields, currentFields, 'name');
        setFields(uniqueData);
      }}
    >
      <Steps labelPlacement="vertical" size="small" current={current} items={steps} />

      <div>{formFieldsUi[current]}</div>
      <S.Row>
        {current > 0 && current < steps.length - 1 && (
          <S.PrevButton type="default" onClick={() => prev()}>
            {t('forms.stepFormLabels.previous')}
          </S.PrevButton>
        )}
        {current < steps.length - 2 && (
          <Button type="primary" onClick={() => next()}>
            {t('forms.stepFormLabels.next')}
          </Button>
        )}
        {current === steps.length - 2 && (
          <Button type="primary" onClick={async () => await onFinish()} loading={isLoading}>
            {t('forms.stepFormLabels.done')}
          </Button>
        )}
      </S.Row>
    </BaseForm>
  );
};
