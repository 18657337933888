import { ReactComponent as FPTIcon } from '@app/assets/icons/fpt-logo.svg';
import React from 'react';
import * as S from './MainSider/MainSider.styles';

export const BottomSiderLogo: React.FC = () => {
  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to="/">
        <FPTIcon width={128} height={128} />
      </S.SiderLogoLink>
    </S.SiderLogoDiv>
  );
};
