import LoginPage from '@app/pages/LoginPage';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import RequireAuthOIDC from '@app/components/router/RequireAuthOIDC';
import { withLoading } from '@app/hocs/withLoading.hoc';
import ChatPage from '@app/pages/DashboardPages/ChatPage';
import CreateChatPage from '@app/pages/DashboardPages/CreateChatPage';
import DataSourceAddPage from '@app/pages/DashboardPages/DataSourceAddPage';
import DataSourcePage from '@app/pages/DashboardPages/DataSourcePage';
import DataSourcesPage from '@app/pages/DashboardPages/DataSourcesPage';
import PartnersPage from '@app/pages/DashboardPages/PartnersPage';

const Logout = React.lazy(() => import('./Logout'));

export const NFT_DASHBOARD_PATH = '/nft';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

const Partners = withLoading(PartnersPage);
const DataSources = withLoading(DataSourcesPage);
const DataSourceAdd = withLoading(DataSourceAddPage);
const DataSource = withLoading(DataSourcePage);
const CreateChat = withLoading(CreateChatPage);
const EditChat = withLoading(ChatPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export interface HandleConfigurationChangeProps {
  configurationName: string;
  handleConfigurationChange: (configurationName: string) => void;
}

export const AppRouter: React.FC<HandleConfigurationChangeProps> = ({
  handleConfigurationChange,
  configurationName,
}) => {
  const protectedLayout = (
    <RequireAuthOIDC>
      <MainLayout />
    </RequireAuthOIDC>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={protectedLayout}>
          <Route index element={<CreateChat />} />
          <Route path="/chats" element={<Partners />} />
          <Route path="/chat/:partnerID" element={<EditChat />} />
          <Route path="/chat/:partnerID/sources" element={<DataSources />} />
          <Route path="/chat/:partnerID/source/:siteID" element={<DataSource />} />
          <Route path="/chat/:partnerID/add/source" element={<DataSourceAdd />} />
          <Route path="/onboarding" element={<CreateChat />} />
          <Route path="/logout" element={<LogoutFallback />} />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route
            path="login"
            element={
              <LoginPage handleConfigurationChange={handleConfigurationChange} configurationName={configurationName} />
            }
          />
          <Route
            path="login/:workspace"
            element={
              <LoginPage handleConfigurationChange={handleConfigurationChange} configurationName={configurationName} />
            }
          />
          <Route path="silent-callback" />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
