import { getRealmFromConfig } from '@app/App';
import { getPartner } from '@app/api/partners.api';
import { Breadcrumb } from '@app/components/common/Breadcrumb/Breadcrumb';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { DataSourcesTable } from '@app/components/tables/BasicTable/DataSourcesTables';
import { Card, TablesWrapper } from '@app/components/tables/Tables/Tables.styles';
import { PartnerModel } from '@app/domain/PartnerModel';
import { readConfigurationName } from '@app/services/localStorage.service';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const DataSourcesPage: React.FC = () => {
  const { partnerID } = useParams();
  const { accessToken } = useOidcAccessToken(readConfigurationName());
  const realm = getRealmFromConfig(readConfigurationName());
  const [partner, setPartner] = useState<PartnerModel | null>(null);
  const fetchPartner = useCallback(() => {
    const headers = {
      'ChatBD-Realm': realm,
      'ChatBD-Client-ID': 'chatbd-dashboard',
      Authorization: `Bearer ${accessToken}`,
    };
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getPartner(partnerID!, headers).then((partner) => {
      setPartner(partner);
    });
  }, [accessToken, partnerID, realm]);

  useEffect(() => {
    fetchPartner();
  }, [fetchPartner]);

  const desktopLayout = (
    <>
      <TablesWrapper>
        <Card id="basic-table" title="Data Sources" padding="1.25rem 1.25rem 0">
          <DataSourcesTable sites={partner?.sites || []} />
        </Card>
      </TablesWrapper>
    </>
  );

  return (
    <>
      <Breadcrumb>
        <Link to="/chats">Chats</Link>
        <div>&nbsp;/&nbsp;</div>
        <Link to={`/chat/${partnerID}`}>{partner?.name}</Link>
      </Breadcrumb>
      <PageTitle>Data Sources</PageTitle>
      {desktopLayout}
    </>
  );
};

export default DataSourcesPage;
