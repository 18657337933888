/* eslint-disable @typescript-eslint/no-empty-function */
import { store } from '@app/store/store';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'config/config';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

if (process.env.REACT_APP_ENV === 'prod') {
  // replace console.* for disable log on production
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};

  // Sentry

  Sentry.init({
    dsn: 'https://f129ad896504417684d617c795fe3fd6@o4504801937653760.ingest.sentry.io/4505126209781760',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  // Mixpanel
  mixpanel.init('6c4b881147080c9f5b5538df8760e64d');
}

// interface EventTarget {
//   state?: 'activated';
// }

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     const waitingServiceWorker = registration.waiting;

//     if (waitingServiceWorker) {
//       waitingServiceWorker.addEventListener('statechange', (event) => {
//         if ((event.target as EventTarget).state === 'activated') window.location.reload();
//       });
//       waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
//     }
//   },
// }); // app will reload if new version of app is available

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
