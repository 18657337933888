import { PartnerModel } from '@app/domain/PartnerModel';
import { SiteModel } from '@app/domain/SiteModel';
import { useAuthHeaders } from '@app/hooks/useAuthHeaders';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

export interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
}

export interface PartnersResponse {
  data: PartnerModel[];
  current?: number;
  pageSize?: number;
  total?: number;
}

export interface PartnersResponse {
  data: PartnerModel[];
  current?: number;
  page_size?: number;
  total?: number;
}

export const getPartners = (pagination: Pagination, headers: AxiosRequestHeaders): Promise<PartnersResponse> => {
  const url = `${process.env.REACT_APP_BASE_URL}/partner/?current=${pagination.current}&page_size=${pagination.pageSize}`;
  const config: AxiosRequestConfig = { headers };
  return axios.get<PartnersResponse>(url, config).then(({ data }) => data);
};

export const getPartner = (partnerID: string, headers: AxiosRequestHeaders): Promise<PartnerModel> => {
  const url = `${process.env.REACT_APP_BASE_URL}/partner/${partnerID}`;
  const config: AxiosRequestConfig = { headers };
  return axios.get<PartnerModel>(url, config).then(({ data }) => data);
};

export const getPartnerSite = (partnerID: string, siteID: string, headers: AxiosRequestHeaders): Promise<SiteModel> => {
  const url = `${process.env.REACT_APP_BASE_URL}/partner/${partnerID}/site/${siteID}`;
  const config: AxiosRequestConfig = { headers };
  return axios.get<SiteModel>(url, config).then(({ data }) => data);
};

export const postPartners = (payload: any, headers?: AxiosRequestHeaders) => {
  const url = `${process.env.REACT_APP_BASE_URL}/partner/`;
  const config: AxiosRequestConfig = { headers };

  return axios.post(url, payload, config).then(({ data }) => data);
};

export const uploadFile = async (file: any) => {
  const formData = new FormData();
  formData.append('file', file);

  const config: AxiosRequestConfig = {
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/file/uploadfile`,
    data: formData,
  };
  // Upload the file using Axios
  const { data } = await axios(config);
  return data;
};

export const triggerScrape = async (payload: any) => {
  const { data } = await axios.post(`${process.env.REACT_APP_SCRAPE_URL}/scrape/`, payload);
  return data;
};

export const patchPartner = async (partnerID: string, payload: any, headers?: AxiosRequestHeaders) => {
  const url = `${process.env.REACT_APP_BASE_URL}/partner/${partnerID}`;
  const config: AxiosRequestConfig = { headers };
  const { data } = await axios.patch(url, payload, config);
  return data;
};

export const addSite = async (partnerID: string, payload: any, headers?: AxiosRequestHeaders) => {
  const url = `${process.env.REACT_APP_BASE_URL}/partner/${partnerID}/site`;
  const config: AxiosRequestConfig = { headers };
  const { data } = await axios.post(url, payload, config);
  return data;
};

export const patchSite = async (partnerID: string, siteID: string, payload: any, headers?: AxiosRequestHeaders) => {
  const config: AxiosRequestConfig = { headers };
  const url = `${process.env.REACT_APP_BASE_URL}/partner/${partnerID}/site/${siteID}`;
  const { data } = await axios.patch(url, payload, config);
  return data;
};
export const deteleSite = async (partnerID: string, siteID: string, headers?: AxiosRequestHeaders) => {
  const config: AxiosRequestConfig = { headers };
  const url = `${process.env.REACT_APP_BASE_URL}/partner/${partnerID}/site/${siteID}`;
  const { data } = await axios.delete(url, config);
  return data;
};

export const postUser = (headers: AxiosRequestHeaders) => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/iam/user`, null, { headers });
};

export const usePartner = (partnerID: string) => {
  const headers = useAuthHeaders();
  return useQuery({
    queryKey: ['partner', partnerID],
    queryFn: () => getPartner(partnerID, headers),
  });
};

export const useSite = (partnerID: string, siteID: string) => {
  const headers = useAuthHeaders();
  return useQuery({
    queryKey: ['site', siteID],
    queryFn: () => getPartnerSite(partnerID, siteID, headers),
  });
};

export const usePatchSite = (partnerID: string, siteID: string) => {
  const queryClient = useQueryClient();
  const headers = useAuthHeaders();
  return useMutation({
    mutationFn: (payload: any) => patchSite(partnerID, siteID, payload, headers),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['site', siteID] });
    },
  });
};

export const useAddSite = (partnerID: string) => {
  const queryClient = useQueryClient();
  const headers = useAuthHeaders();
  return useMutation({
    mutationFn: (payload: any) => addSite(partnerID, payload, headers),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['partner', partnerID] });
    },
  });
};

export const useDeleteSite = (partnerID: string, siteID: string) => {
  const queryClient = useQueryClient();
  const headers = useAuthHeaders();
  return useMutation({
    mutationFn: () => deteleSite(partnerID, siteID, headers),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['site', siteID] });
      queryClient.invalidateQueries({ queryKey: ['partner', partnerID] });
    },
  });
};
