import { usePartner } from '@app/api/partners.api';
import serverError from '@app/assets/images/server-error.svg';
import { Error } from '@app/components/Error/Error';
import { Loading } from '@app/components/common/Loading';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Button } from '@app/components/common/buttons/Button/Button';
import { ChatInfoEditForm } from '@app/components/forms/StepForm/ChatInfoEditForm';
import { DataSourcesTable } from '@app/components/tables/BasicTable/DataSourcesTables';
import { Card } from '@app/components/tables/Tables/Tables.styles';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ChatPage: React.FC = () => {
  const { partnerID } = useParams();
  const { isLoading, error, data: partner } = usePartner(partnerID!);
  const navigate = useNavigate();
  if (isLoading) {
    return <Loading />;
  }

  if (!!error) {
    return <Error img={serverError} msg="Internal server error" />;
  }

  return (
    <>
      <PageTitle>Edit Chat</PageTitle>
      <div>
        <Card id="step-form" title={'Edit ChatBD Bot'} padding="1.25rem 1.25rem 1.875rem">
          <ChatInfoEditForm partner={partner!} />
        </Card>
      </div>
      <div>
        <Card id="basic-table" title="Data Sources" padding="1.25rem 1.25rem 1.875rem">
          <Button
            type="ghost"
            htmlType="submit"
            onClick={() => navigate(`/chat/${partnerID}/add/source`)}
            style={{ marginBottom: '2rem' }}
          >
            Add Data Source
          </Button>
          <DataSourcesTable sites={partner?.sites || []} isLoading={isLoading} />
        </Card>
      </div>
    </>
  );
};

export default ChatPage;
