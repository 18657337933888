import { patchPartner, uploadFile } from '@app/api/partners.api';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import { PartnerModel } from '@app/domain/PartnerModel';
import { useAuthHeaders } from '@app/hooks/useAuthHeaders';
import { useMounted } from '@app/hooks/useMounted';
import { getFilenameFromURL, mergeBy } from '@app/utils/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { InfoStep } from './Steps/InfoStep';
interface FieldData {
  name: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
}

interface Props {
  partner: PartnerModel;
}

export const ChatInfoEditForm: React.FC<Props> = ({ partner }) => {
  const current = 0;
  const [form] = BaseForm.useForm();
  const [fields, setFields] = useState<FieldData[]>([
    { name: 'name', value: '' },
    { name: 'prompt', value: null },
    { name: 'logoUrl', value: '' },
    { name: 'logoFile', value: null },
    { name: 'chat_avatar_file', value: null },
    { name: 'chat_avatar', value: '' },
    { name: 'primaryColor', value: '#09A4C4' },
    { name: 'documents', value: [] },
    { name: 'sites', value: undefined },
    { name: 'languageCode', value: 'en' },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const { isMounted } = useMounted();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const headers = useAuthHeaders();

  const updateFields = useCallback(() => {
    if (isMounted.current) {
      const sites: { baseUrl: string }[] = [];
      const documents = [];
      for (const site of partner.sites) {
        if (new URL(site.base_url).hostname.includes('s3.amazonaws.com')) {
          const filenameWithUUID = getFilenameFromURL(site.base_url);
          const uuid = filenameWithUUID.split('_')[0];
          const originalFilename = filenameWithUUID.split('_').slice(1).join('_');

          documents.push({
            url: site.base_url,
            name: originalFilename,
            type: 'application/pdf',
            uid: uuid,
            status: 'success',
            showRemoveIcon: false,
          });
        } else {
          sites.push({ baseUrl: site.base_url });
        }
      }

      const styles: any = partner.styles || {};
      const primaryColor = styles.primary_color || '#09A4C4';
      const chat_avatar = styles.chat_avatar || '';

      setFields([
        { name: 'name', value: partner.name },
        { name: 'prompt', value: null },
        { name: 'logoUrl', value: partner.logo_url },
        { name: 'chat_avatar', value: chat_avatar },
        { name: 'primaryColor', value: primaryColor },
        { name: 'languageCode', value: partner.language_code },
      ]);
    }
  }, [isMounted, partner.language_code, partner.logo_url, partner.name, partner.sites, partner.styles]);

  useEffect(() => {
    updateFields();
  }, [updateFields]);

  const onFinish = async () => {
    setIsLoading(true);
    let logoUrl = form.getFieldValue('logoUrl');
    const logoFile = form.getFieldValue('logoFile');
    let avatarUrl = form.getFieldValue('chat_avatar');
    const avatarFile = form.getFieldValue('chat_avatar_file');
    if (!logoUrl && !!logoFile) {
      const { url } = await uploadFile(logoFile.originFileObj);
      logoUrl = url;
    }
    if (!avatarUrl && !!avatarFile) {
      const { url } = await uploadFile(avatarFile.originFileObj);
      avatarUrl = url;
    }
    const payload = {
      name: form.getFieldValue('name'),
      logo_url: logoUrl,
      styles: {
        primary_color: form.getFieldValue('primaryColor'),
        chat_avatar: avatarUrl,
      },
      language_code: form.getFieldValue('languageCode'),
    };

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    patchPartner(partner.id, payload, headers)
      .then(() => {
        setIsLoading(false);
        notificationController.success({ message: 'Your changes have been saved!' });
        setFieldsChanged(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
        notificationController.error({ message: 'Something went wrong. Please retry in a few seconds!' });
      });
  };

  const formFieldsUi = [<InfoStep key="1" form={form} partner={partner} setFormFieldsChanged={setFieldsChanged} />];

  return (
    <BaseButtonsForm
      name="chat info edit form"
      form={form}
      fields={fields}
      isFieldsChanged={isFieldsChanged}
      footer={
        <Button type="primary" onClick={async () => await onFinish()} loading={isLoading}>
          Save Changes
        </Button>
      }
      onFieldsChange={(_, allFields) => {
        console.log('fields', fields, allFields);

        const currentFields = allFields
          .map((item) => ({
            name: Array.isArray(item.name) ? item.name[0] : '',
            value: item.value,
          }))
          .filter((item) => !(item.name === 'sites' && !Array.isArray(item.value)));
        console.log('currentFields', currentFields);

        const uniqueData = mergeBy(fields, currentFields, 'name');
        setFields(uniqueData);
        setFieldsChanged(true);
      }}
    >
      <div>{formFieldsUi[current]}</div>
    </BaseButtonsForm>
  );
};
