import { OidcProvider } from '@axa-fr/react-oidc';
import { ConfigProvider } from 'antd';
import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import React, { useReducer, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import 'typeface-lato';
import 'typeface-montserrat';
import { Loading } from './components/common/Loading';
import { AppRouter } from './components/router/AppRouter';
import LoginCallback from './components/router/LoginCallback';
import { useAppSelector } from './hooks/reduxHooks';
import { useAutoNightMode } from './hooks/useAutoNightMode';
import { useLanguage } from './hooks/useLanguage';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import { persistConfigurationName, readConfigurationName } from './services/localStorage.service';
import GlobalStyle from './styles/GlobalStyle';
import { themeObject } from './styles/themes/themeVariables';
import { getRandomInt } from './utils/utils';

const baseConfiguration = {
  authority: `${process.env.REACT_APP_IAM_AUTHORITY}/realms/FPT`,
  client_id: `${process.env.REACT_APP_IAM_CLIENT_ID}`,
  scope: 'openid',
  // service_worker_relative_url: '/OidcServiceWorker.js',
  // silent_login_uri: window.location.origin + '/auth/silent-callback',
  storage: localStorage,
  service_worker_only: false,
  redirect_uri: window.location.origin + '/auth/callback',
  // silent_redirect_uri: window.location.origin + '/auth/silent-callback', // Optional activate silent-signin that use cookies between OIDC server and client javascript to restore the session
  // token_renew_mode: TokenRenewMode.access_token_invalid, // https://github.com/AxaFrance/react-oidc/issues/892#issuecomment-1292108369
};

const configurations = {
  config_FPT: {
    ...baseConfiguration,
    authority: `${process.env.REACT_APP_IAM_AUTHORITY}/realms/FPT`,
  },
  config_chatbd: {
    ...baseConfiguration,
    authority: `${process.env.REACT_APP_IAM_AUTHORITY}/realms/chatbd`,
  },
  config_CyberLotus: {
    ...baseConfiguration,
    authority: `${process.env.REACT_APP_IAM_AUTHORITY}/realms/CyberLotus`,
  },
};

export const WORKSPACE_CONFIG_MAP: { [key: string]: string } = {
  fpt: 'config_FPT',
  chatbd: 'config_chatbd',
  cyberlotus: 'config_CyberLotus',
};

export const CONFIG_WORKSPACE_NAME_MAP: { [key: string]: string } = {
  config_FPT: 'FPT',
  config_chatbd: 'ChatBD',
  config_CyberLotus: 'CyberLotus',
};

export const getRealmFromConfig = (config: string) => {
  const parts = config.split('_');
  return parts[parts.length - 1];
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'event': {
      const id = getRandomInt(9999999999999).toString();
      return [{ ...action.data, id, date: Date.now() }, ...state];
    }
    default:
      throw new Error();
  }
}

const App: React.FC = () => {
  const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);
  const [events, dispatch] = useReducer(reducer, []);
  const [isSessionLost, setIsSessionLost] = useState(false);
  const [configurationName, setConfigurationName] = useState<string>(readConfigurationName());

  if (!readConfigurationName()) {
    persistConfigurationName('config_chatbd');
  }

  const onSessionLost = () => {
    setIsSessionLost(true);
  };

  useAutoNightMode();

  useThemeWatcher();

  const handleConfigurationChange = (configurationName: string) => {
    persistConfigurationName(configurationName);
    setConfigurationName(configurationName);
  };

  const onEvent = (configurationName: string, eventName: string, data: any): void => {
    dispatch({ type: 'event', data: { name: `oidc:${configurationName}:${eventName}`, data } });
  };

  function Fallback({ error }: { error: Error }) {
    console.log(error.message);
    if (error.message.includes('silent_login_uri')) {
      window.location.reload();
    }
    return null;
  }

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <HelmetProvider>
        <ConfigProvider locale={language === 'en' ? enUS : deDe}>
          <ErrorBoundary FallbackComponent={Fallback}>
            <OidcProvider
              configuration={(configurations as any)[configurationName]}
              configurationName={configurationName}
              loadingComponent={Loading}
              authenticatingComponent={Loading}
              onSessionLost={onSessionLost}
              callbackSuccessComponent={LoginCallback}
              onEvent={onEvent}
            >
              {isSessionLost && window.location.replace(window.location.origin)}
              <AppRouter handleConfigurationChange={handleConfigurationChange} configurationName={configurationName} />
            </OidcProvider>
          </ErrorBoundary>
        </ConfigProvider>
      </HelmetProvider>
    </>
  );
};

export default App;
