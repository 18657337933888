import { DeleteOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Upload } from '@app/components/common/Upload/Upload';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { isValidURL } from '@app/utils/utils';
import { Button, Form, FormInstance, UploadFile } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as S from '../StepForm.styles';

const allowedFileTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

interface Props {
  key: string;
  form: FormInstance;
  partner?: any;
}

const MinusCircleOutlinedElm = styled(MinusCircleOutlined)`
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: #777;
  }
`;

const HelpText = styled(Form.ErrorList)`
  & .ant-form-item-explain-error {
    color: var(--ant-info-color);
  }
`;

export const DataSources: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const documents = form.getFieldValue('documents') || [];
  const [ducumentList, setDocumentList] = useState<UploadFile[]>([...documents]);

  const uploadProps = {
    name: 'file',
    defaultFileList: [...ducumentList],
    fileList: [],
    accept: allowedFileTypes.join(),
    beforeUpload: () => {
      return false;
    },
    onChange: (info: { fileList: any }) => {
      const newFileList = [...ducumentList, ...info.fileList];
      setDocumentList(newFileList);
      form.setFieldValue('documents', newFileList);
    },
  };

  const renderFileList = () => {
    return ducumentList.map((file) => {
      return (
        <div key={file.uid} className="file">
          <a href={file.url} target="_blank" rel="noopener noreferrer">
            {decodeURIComponent(file.name)}
          </a>
          <Button type="text" onClick={() => handleDeleteDocument(file)}>
            <DeleteOutlined />
          </Button>
        </div>
      );
    });
  };

  const handleDeleteDocument = (selectedFile: UploadFile) => {
    const newDocumentList = ducumentList.filter((file) => file.uid !== selectedFile.uid);
    form.setFieldValue('documents', newDocumentList);
    setDocumentList(newDocumentList);
  };

  return (
    <S.FormContent>
      <BaseForm.List
        name="sites"
        rules={[
          {
            validator() {
              console.log('validating');

              const documents = form.getFieldValue('documents');
              const sites = form.getFieldValue('sites');
              console.log('validating', sites);
              const isSitesEmpty =
                !sites || sites.filter((s: { [x: string]: string }) => s && isValidURL(s['base_url'])).length === 0;
              console.log(isSitesEmpty);
              if (!documents.length) {
                if (!sites) {
                  return Promise.reject(new Error('Please provide at least 1 data source.'));
                }
                const validSites = sites
                  .filter((s: any) => !!s)
                  .filter((s: { baseUrl: string }) => isValidURL(s.baseUrl));
                console.log('validSites', validSites);
                if (validSites.length === 0) {
                  return Promise.reject(
                    new Error('Please provide at least 1 data source by providing a URL or by uploading a document.'),
                  );
                }
                return Promise.resolve();
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              <div style={{ marginBottom: '10px' }}>
                <HelpText errors={errors} />
              </div>
              {fields.map(({ key, name, ...field }, index) => {
                return (
                  <BaseForm.Item label={index === 0 ? 'Data Sources' : ''} required={false} key={key}>
                    <BaseForm.Item
                      {...field}
                      name={[name, 'baseUrl']}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        () => ({
                          whitespace: false,
                          validator(_, value) {
                            if (!value || isValidURL(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('forms.partnerCreateLables.baseUrlError')));
                          },
                        }),
                      ]}
                      noStyle
                    >
                      <Input style={{ width: '90%' }} />
                    </BaseForm.Item>
                    {/*  if partnerID exist, it means we are editing a chat. Currently, we do not allow to remove data sources*/}
                    <MinusCircleOutlinedElm className="dynamic-delete-button" onClick={() => remove(name)} />
                  </BaseForm.Item>
                );
              })}
              <BaseForm.Item>
                <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />} style={{ width: '300px' }}>
                  Add URL for Data Source
                </Button>
              </BaseForm.Item>
            </>
          );
        }}
      </BaseForm.List>
      <BaseForm.Item
        name="documents"
        getValueFromEvent={(e) => {
          return e.fileList;
        }}
        style={{ marginBottom: 0 }}
      >
        <Upload {...uploadProps} listType="picture">
          <Button type="dashed" icon={<UploadOutlined />} style={{ width: '300px' }}>
            Upload Documents
          </Button>
        </Upload>
      </BaseForm.Item>
      {renderFileList()}
    </S.FormContent>
  );
};
