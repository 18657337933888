import { Popover } from '@app/components/common/Popover/Popover';
import { useResponsive } from '@app/hooks/useResponsive';
import { readConfigurationName } from '@app/services/localStorage.service';
import { useOidc } from '@axa-fr/react-oidc';
import { Avatar, Col, Row } from 'antd';
import logo from 'assets/chatbd_icon.png';
import React from 'react';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import * as S from './ProfileDropdown.styles';

export const ProfileDropdown: React.FC = () => {
  const { isTablet } = useResponsive();
  const { isAuthenticated } = useOidc(readConfigurationName());

  return isAuthenticated ? (
    <Popover content={<ProfileOverlay />} trigger="click">
      <S.ProfileDropdownHeader as={Row} gutter={[10, 10]} align="middle">
        <Col>
          <Avatar src={logo} alt="User" shape="circle" size={40} />
        </Col>
        {isTablet && <Col>{/* <H6>{`${iamUser?.profile.given_name} ${iamUser?.profile.family_name}`}</H6> */}</Col>}
      </S.ProfileDropdownHeader>
    </Popover>
  ) : null;
};
