import { getPartners } from '@app/api/partners.api';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { Option, Select } from '@app/components/common/selects/Select/Select';
import { readConfigurationName } from '@app/services/localStorage.service';
import { isValidURL } from '@app/utils/utils';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { FormInstance, Row, UploadFile } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import { Pagination } from 'api/table.api';
import loadImage, { LoadImageResult } from 'blueimp-load-image';
import { useCallback, useEffect, useState } from 'react';
import { TwitterPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import * as S from '../StepForm.styles';
import UploadImage from './UploadImage';
import { getRealmFromConfig } from '@app/App';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 1000,
};
interface Props {
  form: FormInstance;
  partner?: any;
  setFormFieldsChanged?: (isChanged: boolean) => void;
}

export const languageMapping = (code: string) => {
  switch (code) {
    case 'en':
      return 'English';
    case 'vi':
      return 'Tiếng Việt';
    default:
      return 'English';
  }
};

export const InfoStep: React.FC<Props> = ({ form, partner, setFormFieldsChanged }) => {
  const { t } = useTranslation();
  const [partnerNames, setPartnerNames] = useState<string[]>([]);
  const primaryColor = useWatch('primaryColor', form);
  const logoUrl = useWatch('logoUrl', form);
  const logoFile = useWatch('logoFile', form);
  const chat_avatar = useWatch('chat_avatar', form);
  const chat_avatar_file = useWatch('chat_avatar_file', form);
  const { accessToken } = useOidcAccessToken(readConfigurationName());
  const realm = getRealmFromConfig(readConfigurationName());
  const originalChatName = partner ? partner.name : undefined;
  const [defaultLogoUrl, setDefaultLogoUrl] = useState('https://i.imgur.com/xSBuX0B.png');
  const [defaultAvatarUrl, setDefaultAvatarUrl] = useState('https://i.imgur.com/xSBuX0B.png');

  const fetch = useCallback(
    (pagination: Pagination) => {
      const headers = {
        'ChatBD-Realm': realm,
        'ChatBD-Client-ID': 'chatbd-dashboard',
        Authorization: `Bearer ${accessToken}`,
      };
      getPartners(pagination, headers).then((res) => {
        const names = res.data.map((p) => p.name);
        setPartnerNames(names);
      });
    },
    [accessToken, realm],
  );

  const fetchImage = useCallback(async (url, file, setUrl) => {
    if (!!url) {
      setUrl(url);
    } else if (!!file) {
      const imageData: LoadImageResult = await loadImage(file, { canvas: true });
      const image = imageData.image as HTMLCanvasElement;
      setUrl(image.toDataURL());
    }
  }, []);

  const onLogoUpload = (file: UploadFile | null) => {
    form.setFieldValue('logoUrl', '');
    form.setFieldValue('logoFile', file);
    !!setFormFieldsChanged && setFormFieldsChanged(true);
  };
  const onAvatarUpload = (file: UploadFile | null) => {
    form.setFieldValue('chat_avatar', '');
    form.setFieldValue('chat_avatar_file', file);
    !!setFormFieldsChanged && setFormFieldsChanged(true);
  };

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  useEffect(() => {
    const logoImg = !!logoFile ? logoFile.originFileObj : null;
    const avatarImg = !!chat_avatar_file ? chat_avatar_file.originFileObj : null;
    fetchImage(logoUrl, logoImg, setDefaultLogoUrl);
    fetchImage(chat_avatar, avatarImg, setDefaultAvatarUrl);
  }, [chat_avatar, chat_avatar_file, fetchImage, logoFile, logoUrl]);

  return (
    <S.FormContent>
      <BaseForm.Item
        name="name"
        label={t('forms.partnerCreateLables.name')}
        rules={[
          { required: true, message: t('forms.partnerCreateLables.nameError') },
          () => ({
            validator(_, value) {
              const nameExisted = partnerNames.filter((n) => n === value && n !== originalChatName).length > 0;
              if (!value || !nameExisted) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('forms.partnerCreateLables.nameError')));
            },
          }),
        ]}
      >
        <Input />
      </BaseForm.Item>

      <BaseForm.Item label={'Default Language'} name={'languageCode'}>
        <Select>
          <Option value="en">{languageMapping('en')}</Option>
          <Option value="vi">{languageMapping('vi')}</Option>
        </Select>
      </BaseForm.Item>
      <BaseForm.Item label={'Chat Theme Color'}>
        <Row justify={'space-between'}>
          <div
            style={{
              width: 200,
              height: 50,
              marginTop: 20,
              backgroundColor: primaryColor,
              borderRadius: 8,
            }}
          />
          <BaseForm.Item
            name="primaryColor"
            getValueFromEvent={(e) => {
              return e['hex'];
            }}
          >
            <TwitterPicker color={primaryColor} triangle="hide" width="500" />
          </BaseForm.Item>
        </Row>
      </BaseForm.Item>
      <BaseForm.Item
        name="logoUrl"
        label={'Logo Url'}
        rules={[
          () => ({
            validator(_, value) {
              if (!value || isValidURL(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('forms.partnerCreateLables.baseUrlError')));
            },
          }),
        ]}
        tooltip="You can provide a url or upload a new file"
      >
        <Input onChange={() => form.setFieldValue('logoFile', null)} />
      </BaseForm.Item>
      <BaseForm.Item name="logoFile">
        <UploadImage defaultUrl={defaultLogoUrl} fileName="Logo" onFileChange={onLogoUpload} />
      </BaseForm.Item>
      <BaseForm.Item
        name="chat_avatar"
        label={t('forms.partnerCreateLables.chat_avatar')}
        rules={[
          () => ({
            validator(_, value) {
              if (!value || isValidURL(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('forms.partnerCreateLables.baseUrlError')));
            },
          }),
        ]}
        tooltip="You can provide a url or upload a new file"
      >
        <Input onChange={() => form.setFieldValue('avatar_chat', null)} />
      </BaseForm.Item>
      <BaseForm.Item name="chat_avatar_file">
        <UploadImage defaultUrl={defaultAvatarUrl} fileName="Avatar" onFileChange={onAvatarUpload} />
      </BaseForm.Item>
    </S.FormContent>
  );
};
